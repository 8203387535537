@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Identity by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body {
		&:after {
			display: none;
		}
	}

/* Main */

	#main {
		@include vendor('transform', 'none !important');
		opacity: 1 !important;
	}